@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.home {
  :global .header-section {
    background: rgba(0, 78, 113, 0.1);
    padding: 4rem 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 25px 0 26.28px;
    }
    @include media-breakpoint-up(lg) {
      min-height: calc(100vh - 100px);
      display: flex;
      align-items: center;
      .hero-img {
        max-height: calc(100vh - 130px);
      }
    }

    .santa-img {
      position: fixed;
      top: 75px;
      width: 150px;
      height: 150px;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    .hero-img {
      @include media-breakpoint-up(lg) {
        max-width: 27.25rem;
      }
    }
    .container {
      @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .d-break-new {
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    h1 {
      font-weight: 300;
      font-size: 3.8125rem;
      color: #003c49;
      @include media-breakpoint-up(lg) {
        font-size: 6.5vh;
      }
      @include media-breakpoint-down(md) {
        font-weight: 500;
        font-size: 1.5625rem;
        margin-bottom: 16px !important;
      }
      span {
        &:nth-child(2) {
          white-space: nowrap;
          @media (max-width: 767px) {
            white-space: normal;
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            white-space: normal;
          }
        }
        &:last-child {
          color: #171717;
          font-weight: 700;
          @include media-breakpoint-down(md) {
            font-size: 1.938rem;
            color: #121212;
          }
        }
      }
    }
    p.text-home {
      font-size: 1rem;
      margin-bottom: 3rem;
      @include media-breakpoint-down(md) {
        font-weight: 400;
        color: #535252;
        margin-bottom: 24px !important;
      }
    }
    p.blink {
      a {
        img {
          transition-duration: 0.6s;
          transition-property: transform;
        }
        &:hover {
          img {
            transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
          }
        }
      }
    }
    p {
      font-weight: 400;
      font-size: 1.25rem;
      color: #313131;
      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
        margin-bottom: 40px;
      }
      a {
        transition: all ease 0.6s;
        background: #ffffff;
        padding: 0.25rem 0.25rem 0.25rem 2rem;
        border-radius: 50px;
        color: #050d0e;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: 1rem;
        width: 181px;
        height: 52px;
        @include media-breakpoint-down(md) {
          padding: 2.18182px 2.18182px 2.18182px 17.4545px;
        }
        span {
          &:first-child {
            width: 92px;
            @include media-breakpoint-down(md) {
              font-weight: 400;
              font-size: 1rem;
              color: #050d0e;
            }
          }
          &:nth-child(2) {
            width: 92px;
            display: none;
          }
          &:last-child {
            background: #080a0e;
            padding: 0.75rem;
            border-radius: 3rem;
            margin-left: 2rem;
            gap: 0.5rem;
            display: flex;
            background-image: url(../../public/assets/icons/call-icon.svg);
            background-repeat: no-repeat;
            background-position: center;
            width: 44px;
            height: 44px;
            transition: all ease 0.8s;
            flex: 0 0 44px;
          }
        }
        &:hover {
          background: #3ebfc1;
          padding-left: 0;
          span {
            &:first-child {
              display: none;
            }
            &:nth-child(2) {
              display: block;
              padding-left: 4rem;
              white-space: nowrap;
            }
            &:last-child {
              background: #ffffff;
              transform: translateX(-128px);
              background-image: url(../../public/assets/icons/call-icon-hover.svg);
              background-repeat: no-repeat;
              background-position: center;
              img {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
  :global .progresses-container {
    .new-container {
      padding-right: 0;
      padding-left: 0;
      max-width: 100%;
      @media (min-width: 576px) {
        max-width: 100%;
      }
      @media (min-width: 768px) {
        max-width: 48rem;
      }
      @media (min-width: 992px) {
        max-width: 62rem;
      }
      @media (min-width: 1200px) {
        max-width: 75rem;
      }
      @media (min-width: 1400px) {
        max-width: 87.5rem;
      }
      @media (min-width: 1700px) {
        max-width: 111rem;
      }
    }
  }
  :global .progresses {
    background: #f0f2f2;
    margin-top: -50px;
    padding: 5rem;
    margin-bottom: 7.5rem;
    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      padding: 48px 0;
      margin-bottom: 64px;
    }
    .container {
      @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .logo-mt-3 {
      margin-top: 1rem;

      @include media-breakpoint-down(md) {
        // margin-right: 1.5rem;
        // width: 85px;
      }
    }
    table {
      --bs-table-bg: transparent;
      margin-bottom: 0;
    }
    .h2 {
      font-weight: 500;
      font-size: 3.0625rem;
      color: #005276;
      margin: 2.5rem 0 2rem;
      @include media-breakpoint-down(md) {
        margin-top: 32px;
        font-weight: 500;
        font-size: 1.563rem;
        color: #00415e;
        margin-bottom: 8px !important;
      }
    }
    .partner-logo {
      flex-wrap: wrap;
      // justify-content: center;
    }
    .progress-img {
      margin-right: 1.75rem;
      margin-top: 1rem;
      @include media-breakpoint-down(xxl) {
        margin-right: 1.7rem;
        // width: 125px;
      }
      @include media-breakpoint-down(md) {
        margin-right: 1rem;
        // width: 100px;
        margin-top: 1rem;
      }
    }
    .line-height {
      line-height: 1.8125rem;
      font-size: 1rem;
      @include media-breakpoint-down(md) {
        line-height: 180%;
        font-size: 0.875rem;
        margin-bottom: 40px !important;
      }
    }
    a {
      font-weight: 400;
      font-size: 1rem;
      color: #000000;
      display: block;
      margin-top: 2.5rem;
      text-align: center;
      &:hover {
        color: #3ebfc1;
      }
      @include media-breakpoint-down(md) {
        margin-top: 40px;
        font-size: 0.75rem;
      }
    }
    .cross {
      tr {
        &:first-child {
          border-top: none;
          border-top-width: 0;
          td {
            border-left: none;
            border-left-width: 0;
            text-align: center;
            @include media-breakpoint-down(md) {
              text-align: left;
            }
            p {
              @include media-breakpoint-down(md) {
                margin-bottom: 0;
              }
            }
            &:nth-child(1) {
              @include media-breakpoint-down(md) {
                margin: 0;
                padding: 16px;
              }
            }
            &:nth-child(2) {
              border-right: none;
              border-right-width: 0;
              @include media-breakpoint-down(md) {
                display: flex;
                justify-content: flex-start;
                margin: 16px;
                padding: 0;
              }
            }
          }
        }
        &:last-child {
          border-bottom: none;
          border-bottom-width: 0;
          p {
            @include media-breakpoint-down(md) {
              margin-bottom: 0;
            }
          }
          td {
            border-left: none;
            border-left-width: 0;
            text-align: center;
            &:nth-child(1) {
              @include media-breakpoint-down(md) {
                margin: 0;
                padding: 16px;
                text-align: left;
              }
            }
            &:nth-child(2) {
              border-right: none;
              border-right-width: 0;
              @include media-breakpoint-down(md) {
                display: flex;
                justify-content: flex-start;
                margin: 16px;
                padding: 0;
              }
            }
          }
        }
      }
      h2 {
        font-weight: 700;
        font-size: 3.8125rem;
        color: #080a0e;
        @include media-breakpoint-down(md) {
          font-weight: 700;
          font-size: 1.938rem;
          color: #040d0d;
        }
      }
      p {
        font-weight: 400;
        font-size: 1rem;
        color: #080a0e;
        opacity: 0.7599999904632568;
        @include media-breakpoint-down(md) {
          font-weight: 400;
          font-size: 0.875rem;
          color: #545959;
        }
      }
    }
  }
  :global .transformation {
    background: #f0f2f2;
    padding: 7.5rem 0;
    @include media-breakpoint-down(md) {
      padding: 64px 0;
    }
    .container-fluid {
      @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    h2 {
      margin-bottom: 4rem;
      @include media-breakpoint-down(md) {
        color: #00415e;
        margin-bottom: 32px !important;
      }
    }
    .nav {
      @media (max-width: 991px) {
        display: flex;
        flex-direction: unset !important;
        flex-wrap: unset !important;
      }
      @include media-breakpoint-down(md) {
        display: block;
      }
      .nav-item {
        width: 350px;
        @media (max-width: 991px) {
          width: auto;
        }
        @include media-breakpoint-down(md) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          &:last-child {
            border-bottom: none;
          }
        }
        a {
          background: #ebeded;
          font-weight: 400;
          font-size: 1rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          border-radius: 0;
          color: #040d0d;
          border-left: 6px solid #ebeded;
          @media (max-width: 599px) {
            font-size: 1rem;
            padding: 5px;
          }
          @media (max-width: 499px) {
            font-size: 0.875rem;
            padding: 5px;
          }
          @media (max-width: 399px) {
            font-size: 0.75rem;
            padding: 5px;
          }
          @include media-breakpoint-down(md) {
            border-left: 6px solid transparent;
            padding: 18px 20px 18px 14px;
            background: #ffffff;
            font-size: 1rem;
          }
        }
        a.active {
          background: #ffffff;
          border-left: 6px solid #00415e;
          color: #00415e;
          font-weight: 600;
          @include media-breakpoint-down(md) {
            padding: 18px 20px 18px 14px;
            background: rgba(0, 65, 93, 0.16);
            font-weight: 400;
            font-size: 1rem;
          }
        }
      }
    }
    .tab-content {
      @include media-breakpoint-down(md) {
        margin-top: 16px;
      }
      .swiper-slide {
        width: 38.4375rem;
        @include media-breakpoint-down(md) {
          width: 100% !important;
        }
      }
      .box {
        max-width: 38.4375rem;
        background: #ffffff;
        @include media-breakpoint-down(md) {
          width: 100% !important;
          max-width: 100% !important;
        }
        .transform-bg {
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          @include media-breakpoint-down(xxl) {
            height: 264px;
          }
        }
        .transformation-img-container {
          padding: 0;
          bottom: 0;
          a {
            font-weight: 400;
            font-size: 1rem;
            color: #00415e;
            display: flex;
            align-items: center;
            padding: 0 2rem 2rem;
            @include media-breakpoint-down(md) {
              font-weight: 500;
              font-size: 0.875rem;
              margin-right: 9.33px;
              padding: 0 1rem 2rem;
              img {
                width: 13.33px;
                height: 13.33px;
              }
            }
          }
        }
        .transformation-img {
          height: 13.6875rem;
        }
        div {
          background: #ffffff;
          padding: 2rem 2rem 0;
          @include media-breakpoint-down(md) {
            padding: 24px 16px;
          }
          h5 {
            font-weight: 500;
            font-size: 1.9375rem;
            color: #040d0d;
            @include media-breakpoint-down(md) {
              font-size: 1.125rem;
              margin-bottom: 16px !important;
            }
          }
          p.normal-text {
            @include media-breakpoint-down(md) {
              margin-bottom: 0 !important;
            }
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2rem;
            padding: 0;
            margin-left: 15px;
            @include media-breakpoint-down(md) {
              display: none;
            }
            li {
              flex: 50%;
              font-weight: 400;
              font-size: 0.8125rem;
              color: #545959;
              @include media-breakpoint-down(md) {
                font-size: 0.875rem;
              }
            }
          }
        }
      }
    }
  }
  :global .choose {
    padding: 15rem 0;
    @include media-breakpoint-down(md) {
      padding: 64px 0;
    }
    .container {
      @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    h2 {
      color: #040d0d;
      margin-bottom: 2rem;
      @include media-breakpoint-down(md) {
        color: #00415e;
        margin-bottom: 16px !important;
      }
    }
    .mb-32 {
      margin-bottom: 32px;
    }
    .mb-md-32 {
      @include media-breakpoint-down(md) {
        margin-bottom: 32px;
      }
    }
    .choose-text {
      font-weight: 400;
      font-size: 1rem;
      color: #545959;
      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
      }
    }
    p.normal-text {
      margin-bottom: 2.5rem !important;
    }
    .nav {
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      margin-bottom: 2.5rem;
      @include media-breakpoint-down(md) {
        display: block;
        margin-bottom: 40px !important;
      }
      .nav-item {
        @include media-breakpoint-down(md) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          &:last-child {
            border-bottom: none;
          }
        }
        .nav-link {
          padding: 1rem 1.5rem !important;
          font-weight: 600;
          font-size: 1rem;
          color: #040d0d;
          border-radius: 0;
          margin-bottom: 0;
          &:hover {
            border: 1px solid transparent;
          }
          @include media-breakpoint-down(md) {
            width: 100%;
            background: #f0f2f2;
            border-left: 6px solid transparent;
            font-weight: 400;
            font-size: 1rem;
            color: #545959;
            padding: 20px 20px 20px 14px;
            text-align: left;
          }
          @include media-breakpoint-up(md) {
            width: 14.875rem;
          }
        }
        .active {
          padding: 16px 50px;
          background: #00415e;
          color: #ffffff;
          font-weight: 700;
          @include media-breakpoint-down(md) {
            background: rgba(0, 65, 93, 0.16);
            border-left: 6px solid #00415e !important;
            font-weight: 400;
            font-size: 1rem;
            color: #00415e;
            padding: 20px 20px 20px 14px;
            text-align: left;
          }
          @include media-breakpoint-up(md) {
            width: 14.875rem;
            padding: 16px 24px;
          }
        }
      }
    }
    h4 {
      font-weight: 500;
      font-size: 1.9375rem;
      color: #040d0d;
      @include media-breakpoint-down(md) {
        font-size: 1.563rem;
        margin-bottom: 16px !important;
      }
    }
    p.normal-text {
      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
        margin-bottom: 32px !important;
      }
    }
    .single {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(md) {
        display: block;
      }
      h5 {
        flex: 0.5;
        font-weight: 500;
        font-size: 1.375rem;
        color: #040d0d;
        @include media-breakpoint-down(md) {
          font-weight: 500;
          font-size: 1.125rem;
          margin-bottom: 16px !important;
        }
      }
      p {
        margin-left: 1.94rem;
        @include media-breakpoint-down(md) {
          margin-left: 0;
          font-size: 0.875rem;
        }
      }
    }
    .line {
      @include media-breakpoint-down(md) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
        margin: 32px 0 !important;
      }
    }
  }
  :global .case {
    // padding: 15rem 0;
    padding: 10rem 0;
    @include media-breakpoint-down(lg) {
      padding: 64px 0;
    }
    .container {
      @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    h2 {
      span {
        color: #040d0d;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 16px !important;
      }
    }
    p.normal-text {
      font-size: 0.875rem;
      margin-bottom: 32px;
    }
    img {
      margin-bottom: 32px;
    }
    .nav {
      @media (max-width: 991px) {
        display: flex;
        flex-direction: unset !important;
        flex-wrap: unset !important;
      }
      @include media-breakpoint-down(md) {
        display: block;
      }
      .nav-item {
        @media (max-width: 991px) {
          flex: 1;
        }
        @include media-breakpoint-down(md) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          &:last-child {
            border-bottom: none;
          }
        }
        a {
          background: #ebeded;
          font-weight: 400;
          font-size: 1rem;
          padding: 1.5625rem 1.25rem !important;
          border-radius: 0;
          color: #040d0d;
          border-left: 6px solid #ebeded;
          @include media-breakpoint-down(md) {
            font-size: 1rem;
            color: #545959;
            padding: 20px 20px 20px 14px;
            background: #f0f2f2;
            border-left: 6px solid #f0f2f2;
          }
        }
        a.active {
          background: rgba(0, 65, 93, 0.16);
          border-left: 6px solid #00415e;
          color: #00415e;
          font-weight: 600;
          @include media-breakpoint-down(md) {
            font-weight: 400;
            font-size: 1rem;
            padding: 20px 20px 20px 14px;
            background: rgba(0, 65, 93, 0.16);
            border-left: 6px solid #00415e;
          }
        }
      }
    }
    .tab-content {
      @media (max-width: 991px) {
        margin-top: 20px;
      }
      @include media-breakpoint-down(md) {
        margin-top: 16px;
      }
      .box {
        padding: 30px;
        @include media-breakpoint-down(md) {
          padding: 16px;
          margin-bottom: 32px;
        }
        h5 {
          font-weight: 500;
          font-size: 1.375rem;
          color: #00415e;
          margin-bottom: 0.75rem;
          @include media-breakpoint-down(md) {
            font-size: 1.125rem;
            color: #040d0d;
            margin-bottom: 12px;
          }
        }
        p {
          font-weight: 400;
          font-size: 1rem;
          color: #555555;
          margin-bottom: 1.12rem;
          @include media-breakpoint-down(md) {
            font-size: 0.875rem;
            color: #545959;
            margin-bottom: 16px;
          }
        }
        a {
          font-weight: 400;
          font-size: 1rem;
          color: #00415e;
          text-decoration: underline;
          @include media-breakpoint-down(md) {
            font-weight: 600;
            font-size: 0.875rem;
          }
          img {
            margin-bottom: 0;
            @include media-breakpoint-down(md) {
              margin-left: 8px;
              width: 16.56px;
              height: 10.24px;
            }
          }
        }
        &:hover {
          background: #00415e;
          h5,
          a {
            color: #ffffff;
          }
          p {
            color: #ffffff;
            opacity: 0.8;
          }
          img {
            filter: brightness(0) saturate(100%) invert(99%) sepia(49%)
              saturate(2%) hue-rotate(120deg) brightness(119%) contrast(100%);
          }
        }
      }
    }
  }
  :global .contact-fixed {
    h3 {
      @include media-breakpoint-down(md) {
        color: #040d0d;
      }
    }
    .container {
      @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
